/**
 * TODO:
 * TIPOS DE ALERTAS
 */
export const ALERT_TYPES = {
    "NO_ALERT": { icon: "fa-thumbs-up", text: "alerts.type.noAlert", color: "#08cc33", type: "NO_ALERT" },
    "ALERT_1": { icon: "fa-exclamation-circle", text: "alerts.type.alert1", color: "#00bbff", type: "ALERT_1" },
    "ALERT_2": { icon: "fa-exclamation-circle", text: "alerts.type.alert2", color: "#fff705", type: "ALERT_2" },
    "ALERT_3": { icon: "fa-exclamation-circle", text: "alerts.type.alert3", color: "#ed0e0e", type: "ALERT_3" },
}

/**
 * opciones para el gráfico velocímetro de HighCharts 
 */
export const SPEEDOMETER_CHART_OPTIONS = {
    chart: {
      type: 'solidgauge',
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },

    title: null,
  
    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: 'transparent',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },
  
    exporting: {
      enabled: false
    },
  
    tooltip: {
      enabled: false
    },
  
    // the value axis
    yAxis: {
      stops: [
        [0.1, '#55BF3B'], // green
        [0.5, '#DDDF0D'], // yellow
        [0.9, '#DF5353'] // red
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70
      },
      labels: {
        y: 16
      }
    },
  
    plotOptions: {
      solidgauge: {
        dataLabels: {
          borderWidth: 0,
          useHTML: true
        }
      }
    }
}

/**
 * MINUTOS del interval para actualizar datos
 */
export const REFRESH_INTERVAL = 1

/**
 * CLAVE DE ALARMAS A NUMEROS
 */
export const NUMBER_ALARM = {
  ALERT_1: 1,
  ALERT_2: 2,
  ALERT_3: 3
}

export const NUMBER_ALARM_TO_KEY = {
  "0": "NO_ALERT",
  "1": "ALERT_1",
  "2": "ALERT_2",
  "3": "ALERT_3"
}

export const CIRCUIT_TEXT_KEY = {
  "01": "c1",
  "02": "c2",
  "03": "c3",
  "04": "c4",
  "05": "c5",
  "06": "c6"
}