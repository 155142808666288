import AlertCardComponent from '@/components/alerts/alert-card/AlertCardComponent.vue'
import NoDataAvailableComponent from '@/components/commons/no-data-available/NoDataAvailableComponent.vue'
import FilterComponent from '@/components/filter/FilterComponent.vue'
import { configurationService } from "@/business/configurationService"
import { filterService } from "@/business/filterService"
import alertsService from '@/business/alerts.service'
import { SnackbarStyle } from '@/constants/constants'
import { mapActions } from 'vuex'
// El logo de Gestya
import base64 from '../../assets/base64'
// Para exportar a formato pdf
import jsPDF from 'jspdf'
// Para exportar los canvas a diferentes formatos
import { saveAs } from 'file-saver'
// La función para activar la pantalla completa
import { toggleFullscreen } from '../../tools/functions'
// Para exportar elementos del DOM a imágenes
import domtoimage from 'dom-to-image'
// Para clonar objetos
import { clone } from 'ramda'
import { CONFIGURATION_KEY } from '@/constants/constants'
import { REFRESH_INTERVAL } from '@/constants/alert.constant'

export default {
    name: 'AlertsComponent',
    components: {
        FilterComponent,
        AlertCardComponent,
        NoDataAvailableComponent,
    },
    props: {
        width: {
            required: false,
            default: () => { return '100%' }
        },
        height: {
            required: false,
            default: () => { return '100%' }
        }
    },
    data: () => ({
        // el logo de gestya
        gestyaLogoBase64: null,
        // el logo de la empresa
        enterpriseLogoBase64: null,
        // ícono del botón para ir/salir de pantalla completa
        fullscreenIcon: 'fullscreen',
        // loading de exportación
        exportLoading: false,
        // search
        searchValue: null,
        // mostrar-ocultar los filtros
        showFilters: false,
        // modelo del filtro
        filterModel: filterService.getFilterModel(),
        // TODO: definir propiedad de rango
        dataForRangeFilter: {
            showRangeData: false,
            minLimit: 0,
            maxLimit: 1000,
            propertyName: ''
        },
        maxQuantityLimit: 100,
        // TODO: definir propiedad de ordenamiento
        orderFilterWorstBestPropertyName: 'alarmas',
        // datos
        data: [],
        // loading datos
        loading: false,
        // para no mostrar el mensaje "no hay datos disponibles" al inicio
        init: true,
        // instance del setInterval para actualizar datos
        intervalInstance: null,
        speedUnit:'km/h',
        pressureUnit: 'psi'
    }),
    async mounted() {
        // Generar Base64 del logo de Gestya
        this.gestyaLogoBase64 = base64.gestya
        // Generar Base64 del logo de la empresa
        if (localStorage.getItem('logo')) {
            this.enterpriseLogoBase64 = localStorage.getItem('logo')
        }
        // recuperar configuración de filtros y obtrener datos
        await this.getFilterConfigurationForReport();
        if (this.filterModel) {
            await this.getReport(this.filterModel);
            this.showFilters = false;
        } else {
            this.showFilters = true;
        }
        // autorefresh cada 5 minutos
        this.intervalInstance = setInterval(() => {
            this.getReport(this.filterModel)
        }, REFRESH_INTERVAL * 60 * 1000);
    },
    created() { },
    beforeDestroy() {
        this.closeReportDefer()
        this.cancelAutoRefresh()
    },
    computed: {
        /**
         * Filtra las tarjetas de acuerdo al valor del campo de búsqueda
         * @returns
         */
        filteredAlerts() {
            if (this.searchValue) {
                const fields = ['thingName', 'currentPerformance', 'currentFuel', 'alert']
                const regex = new RegExp(this.searchValue, 'i')
                const filterFunction = (element) => {
                    let result = false
                    fields.forEach(field => {
                        result = result || regex.test(element[field])
                    })
                    return result
                }
                return this.data.filter(element => filterFunction(element))
            } else {
                return this.data
            }
        },
        /**
         * Para ocultar el scrollbar en la exportación
         */
        exportCards() {
            if (this.exportLoading) {
                return 'hide-scrollbar'
            }
        },
        exportCardsRow() {
            if (this.exportLoading) {
                return 'pr-3'
            }
        },
        /**
         * TODO: detalles
         */
        details() {
            return ''
        },
        /**
         * Para ocultar los detalles
         */
        exportDetails() {
            if (!this.exportLoading) {
                return 'hidden'
            } else {
                return 'visible'
            }
        }
    },
    methods: {
        ...mapActions({
            showSnackbar: "snackbar/showSnackbar",
            showReportDefer: 'reportDefer/showReportDefer',
            closeReportDefer: 'reportDefer/closeReportDefer',
        }),
        /**
         * Para mostrar el panel de filtros
         */
        showFilter() {
            this.showFilters = !this.showFilters
        },
        /**
         * Método que se ejecuta cuando recibe el evento "saveChanges"
         */
        saveFilterChanges() {
            this.showFilters = false
            this.saveFilterConfigurationForReport({ filterModel: this.filterModel })
            this.getReport(this.filterModel)
        },
        /**
         * Método para guardar la configuración según el usuario de los filtros aplicados para el reporte
         * @param {*} configuration
         */
        saveFilterConfigurationForReport(configuration) {
            configurationService
                .save(CONFIGURATION_KEY.PRODUCTS_ALERT_REPORT, configuration)
                .catch(() => {
                    this.showSnackbar({
                        visible: true,
                        text: this.$t("user.configuration.saveError"),
                        timeout: 10000,
                        style: SnackbarStyle.ERROR
                    })
                })
        },
        /**
         * Método para obtener la configuración de los filtros para el reporte según el usuario actual, si la hubiera
         */
        async getFilterConfigurationForReport() {
            const configuration = await configurationService.get(CONFIGURATION_KEY.PRODUCTS_ALERT_REPORT)
            const data = configuration && configuration.data
            if (data != null && data.filterModel != null) {
                this.filterModel = clone(data.filterModel)
            }
            this.showFilters = true
        },
        /**
         * Obtener datos para el reporte
         * @param {*} filters
         */
        getReport(filters) {
            this.loading = true
            alertsService.getAlertsData(filters).then(data => {
                if (data != null) {
                    this.data.cleanAndUpdate(data)
                    this.pressureUnit = data.pressureUnit
                    this.speedUnit = data.speedUnit
                    this.init = false
                } else {
                    this.showFilters = true
                }
                this.loading = false
            })
        },
        /**
         * Exportar a una imagen en formato PNG
         */
        async exportToImage(png = true) {
            this.exportLoading = true
            const date = new Date()
            const title = `${this.$t('exportFileName.alerts')}_${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
            const node = document.getElementById('cardsForExport')
            const blob = await domtoimage.toBlob(node, { width: node.scrollWidth, height: node.scrollHeight })
            if (png) {
                saveAs(blob, `${title}.png`)
            } else {
                saveAs(blob, `${title}.jpg`)
            }
            this.exportLoading = false
        },
        /**
         * Método para exportar a PDF
         */
        async exportToPDF(print = false) {
            this.exportLoading = true
            const date = new Date()
            const title = `${this.$t('exportFileName.alerts')}_${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

            // tarjetas
            const node = document.getElementById('cardsForExport')
            const dataUrl = await domtoimage.toPng(node, { height: node.scrollHeight })

            // GENERO EL PDF Y LO GUARDO
            const doc = jsPDF('l', 'mm', 'a4')
            doc.page = 1
            // Ancho y altura de la imagen en el pdf
            const pdfWidth = doc.internal.pageSize.getWidth()
            // const pdfHeight = ratio * pdfWidth
            doc.addImage(dataUrl, 'PNG', 0, 20)
            // Header
            if (this.enterpriseLogoBase64) {
                doc.addImage(this.enterpriseLogoBase64, 'PNG', 5, 2, 50, 10)
            }
            doc.line(5, 15, pdfWidth - 5, 15)
            // Footer
            doc.addImage(this.gestyaLogoBase64, 'PNG', 5, doc.internal.pageSize.height - 12, 50, 10)
            doc.line(5, doc.internal.pageSize.height - 15, pdfWidth - 5, doc.internal.pageSize.height - 15)

            if (print) {
                // Imprimir el pdf
                doc.autoPrint()
                window.open(doc.output('bloburl'), '_blank')
            } else {
                // Descargar el pdf
                doc.save(title)
            }

            this.exportLoading = false
        },
        /**
         * Para ir a pantalla completa
         */
        goToFullscreen() {
            const tabsContainer = document.getElementById('AlertsComponent')
            this.fullscreenIcon = toggleFullscreen(tabsContainer)
        },
        /**
         * Ícono del botón de pantalla completa
         */
        switchFullscreenIcon() {
            if (!document.fullscreenElement && !document.mozFullScreenElement &&
                !document.webkitFullscreenElement && !document.msFullscreenElement) {
                this.fullscreenIcon = 'fullscreen'
            } else {
                this.fullscreenIcon = 'fullscreen_exit'
            }
        },
        cancelAutoRefresh() {  
            clearInterval(this.intervalInstance)  
        }
    }
}