/* eslint-disable no-unused-vars */
import i18n from '@/i18n'
import alertsApi from '@/api/alerts.api'
import { parseTimestamp, getChildrenFromList } from '@/tools/functions'
import { ReportConversionUtil, SortOptions, TIMESTAMP_COLOR } from '@colven/common-domain-lib/lib'
import { ALERT_TYPES, NUMBER_ALARM_TO_KEY, NUMBER_ALARM, CIRCUIT_TEXT_KEY } from '../constants/alert.constant'
import {unitParser} from '@colven/common-domain-lib/lib'
import store from "@/store/store";
import { parseDateWithTimezone, parseTimeWithTimezone, processFilters } from "../tools/functions";

/*
    mocked data para realizar pruebas
    TIENE LA MISMA ESTRUCTURA DE DATOS QUE LA COLLECTION thingState
*/
const mockedData = [
    {
        idThing: 'thing1',
        thingName: 'thing1 AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA',
        timestamp: 1645105731,
        trackTimestamp: 1645105731,
        engineRunning: true,
        speed: 75.22,
        caliper: {
            calibratingHardware: {
                "01" : false,
                "02" : false,
                "03" : true,
            },
            hardwarePsi: {
                "01" : 105,
                "02" : 110,
                "03" : 98,
                "05": null,
            },
            pressureIn: 105,
            maxPressure: {
                "01" : 140,
                "02" : 140,
                "03" : 140,
            }
        },
        position: {
            lat: '-31.6471935',
            lng: '-60.700072',
        },
        georeference: 'Francisco Miguens 180 - Santa Fe AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA AAAAAA',
    },
    {
        idThing: 'thing2',
        thingName: 'thing2',
        timestamp: 1645105731,
        trackTimestamp: 1645105731,
        engineRunning: null,
        speed: 180,
        caliper: {
            calibratingHardware: {
                "01" : false,
                "03" : true
            },
            hardwarePsi: {
                "01" : 105,
                "02" : 110,
                "03" : 98
            },
            maxPressure: {
                "01" : 140,
                "02" : 140,
                "03" : 140
            },
            pressureIn: 105
        },
        position: {
            lat: '-31.6471935',
            lng: '-60.700072',
        },
        georeference: null,
    },
    {
        idThing: 'thing3',
        thingName: 'thing3',
        timestamp: 1645105731,
        trackTimestamp: 1645105731,
        engineRunning: false,
        speed: null,
        caliper: {
            calibratingHardware: {
                "01" : false,
                "02" : false,
                "04" : true,
                "05" : true,
                "08" : true
            },
            hardwarePsi: {
                "01" : 105,
                "02" : 110,
                "03" : 98,
                "04" : 70,
                "05" : 1,
                "08" : 44
            },
            maxPressure: {
                "01" : 140,
                "02" : 140,
                "03" : 140,
                "04" : 140,
                "05" : 140,
                "08" : 140
            },
            pressureIn: 105
        },
        position: {
            lat: '-31.6471935',
            lng: '-60.700072',
        },
        georeference: 'Francisco Miguens 180 - Santa Fe',
    }
]

/**
 * Obtener los datos para el reporte de alertas
 * @param {*} filters
 * @returns
 */
const getAlertsData = async (filters) => {
    try {
        // el modelo de los filtros se procesan, así se envía una estructura más limpia para generar los datos del reporte
        // este reporte no es diferido, entonces los filtros no se guardarán con los datos del reporte
        let idThings = []
        getChildrenFromList(filters.generalData.selectedThings, idThings)
        idThings = idThings.map(t => t.id)
        const limit = filters.generalData.maxQuantity ? Number(filters.generalData.maxQuantity) : null
        const sort = filters.orderData.selectedSortOption
        const response = await alertsApi.getAlertsData({ idThings, limit, sort })
        let data = response.data;
        if(sort === SortOptions.BEST_FIRST) {
            data = data.sort((a, b) => compareBestWorst(a, b, true));
        }
        else if(sort === SortOptions.WORST_FIRST) {
            data = data.sort((a, b) => compareBestWorst(a, b, false));
        }
        return processData(data)
    } catch (exception) {
        console.error(exception)
        return []
    }
    // para realizar pruebas:
    // return processData(mockedData)
}

/**
 * Procesar los datos para el reporte de alertas
 * @param {*} data
 * @returns
 */
const processData = async (data) => {
    console.log("Data: ", data)
    data.speedUnit = unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.SPEED);
    data.pressureUnit = unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.PRESSURE);
    // procesamiento de datos
    data.forEach(element => {
        console.log("VAR: ",store.getters['user/getInternationalization'])
        // fecha de comunicación
        let timestampDateFormatted = parseDateWithTimezone(element.timestamp, store.getters['user/getInternationalization'].dateMask);
        let timestampTimeFormatted = parseTimeWithTimezone(element.timestamp, store.getters['user/getInternationalization'].date);
        const timestampFormatted = timestampDateFormatted + " " + timestampTimeFormatted;
        element.communicationDate = timestampFormatted
        element.communicationDateColor =  ReportConversionUtil.getTimestampColor(element.timestamp)

        // fecha de posición
       // const trackTimestampFormatted = unitParser.parseDateAndTime(applyTimezone(element.trackTimestamp), store.getters['user/getInternationalization'].dateMask, store.getters['user/getInternationalization'].date)
        let trackTimestampDateFormatted = parseDateWithTimezone(element.trackTimestamp, store.getters['user/getInternationalization'].dateMask);
        let trackTimestampTimeFormatted = parseTimeWithTimezone(element.trackTimestamp, store.getters['user/getInternationalization'].date);
        const trackTimestampFormatted = trackTimestampDateFormatted + " " + trackTimestampTimeFormatted;
        element.positionDate = trackTimestampFormatted
        element.positionDateColor = ReportConversionUtil.getTimestampColor(element.trackTimestamp)

        // leyenda "sin datos de georeferencia"
        if (!element.georeference) {
            element.georeference = i18n.t('alerts.noGeoreference')
        }

        // lat y long: conversión a Number
        if (element.position != null) {
            element.position.lat = element.position.lat ? parseFloat(element.position.lat) : null
            element.position.lng = element.position.lng ? parseFloat(element.position.lng) : null
        }

        if(element.speed) {
            element.speed = Number(unitParser.parseSpeed(Number(element.speed.toFixed(0)), store.getters['user/getInternationalization'].unit, false))
        }

        // formateo de datos de los circuitos
        const circuits = []
        const caliper = element.caliper
        const fixedPressures = element.fixedPressures || {};
        if (caliper != null
            && caliper.hardwarePsi != null
            && caliper.calibratingHardware != null
            && fixedPressures != null) {
                const circuitKeys = Object.keys(fixedPressures); //los circuitos configurados en el thing son los habilitados
                //PRESIÓN MÁXIMA
                const circuitsMaxPressures = getCircuitsMaxPressures(fixedPressures, circuitKeys, caliper.pressureState?.general ? caliper.pressureState.general : caliper.pressureState);
                circuitKeys.forEach(key => {
                    circuits.push({
                        key,
                        currentPressure: caliper.hardwarePsi[key] ?  Number(unitParser.parsePressure(caliper.hardwarePsi[key], store.getters['user/getInternationalization'].unit, false)) : null,
                        inletPressure: caliper.pressureIn ?  Number(unitParser.parsePressure(caliper.pressureIn, store.getters['user/getInternationalization'].unit, false)) : null,
                        maxPressure: circuitsMaxPressures[key] ?  Number(unitParser.parsePressure(circuitsMaxPressures[key], store.getters['user/getInternationalization'].unit, false)) : null,
                        isCalibrating: caliper.calibratingHardware[key] != null && caliper.pressureIn ? caliper.calibratingHardware[key] : null,
                        fixedPressures: {
                            base: fixedPressures[key].base ? Number(unitParser.parsePressure(fixedPressures[key].base, store.getters['user/getInternationalization'].unit, false)) : null,
                            high: fixedPressures[key].high ? Number(unitParser.parsePressure(fixedPressures[key].high, store.getters['user/getInternationalization'].unit, false)) : null,
                            low: fixedPressures[key].low ? Number(unitParser.parsePressure(fixedPressures[key].low, store.getters['user/getInternationalization'].unit, false)) : null,
                            type: fixedPressures[key].type || null
                        },
                        pressureCaliper: caliper[`circuit${key}`]
                    })
                })
        }
        
        element.circuits = circuits
        
        // Se muestra el estado solo para los equipos del tipo NM-444 y para los del tipo Panel
        element.showState = false;
        element.showPanelState = false;
        if(element.fixedPressures) {
            if (Object.values(element.fixedPressures).filter((e) => e.type == 'L-H').length > 0) {
                element.showState = true;
            }
            else if (Object.values(element.fixedPressures).filter((e) => e.type == 'P').length > 0) {
                element.showPanelState = true;
            }
        }
        // Definición de alertas: mostrar la alerta más grave, de haberla, según el dato de los circuitos
        setAlert(element)
    })

    return data
}

const applyTimezone = (date)=>{
    //Aplica el timezone a una fecha en formato unix y retorna la fecha en formato unix tambien
    let timezone = parseInt(localStorage.getItem('enterpriseTimezone'))
    let parseDate = null;
    parseDate = parseInt(date); 
    parseDate = parseDate + (timezone * 60 * 60); 
    return parseDate;
}

/**
 * Formatear el timestamp (texto y color)
 * @returns
 */
const getTimestampFormatted = (timestamp) => {
    const result = { timestampFormatted: null, timestampColor: null }
    if (timestamp != null) {
        result.timestampFormatted = parseTimestamp(timestamp)
        result.timestampColor = ReportConversionUtil.getTimestampColor(timestamp)
    }
    return result
}

/**
 * Si alguno de los circuitos marca alerta, se mostrará que el equipo está en alerta, recalcando la más grave
 * Si ningún circuito marca alerta entonces se muestra "Sin alerta".
 * @param {*} element
 */
const setAlert = (element) => {
    const circuitsEnabled = element.fixedPressures ? Object.keys(element.fixedPressures) : []; //keys de los circuitos 
    let alert = element?.caliper?.states ? setHighestAlarm(element.caliper.states, circuitsEnabled) : null;
    if (element?.caliper?.states) setCircuitsColors(element, circuitsEnabled);
    element.alertIcon = ALERT_TYPES[alert.type].icon
    element.alertColor = ALERT_TYPES[alert.type].color
    element.alert = i18n.t(ALERT_TYPES[alert.type].text) + alert.circuits
}

const setCircuitsColors = (element, thingCircuitsEnabled) => {
    element.color = null;
    thingCircuitsEnabled.forEach(circuitKey => {
        switch (NUMBER_ALARM[element.caliper.states[circuitKey]]) {
            case 1:
                element.circuits.map(c => { if(c.key == circuitKey) c.color = '#00bbff'})
                break;
            case 2:
                element.circuits.map(c => { if(c.key == circuitKey) c.color = '#fff705'})
                break;
            case 3:
                element.circuits.map(c => { if(c.key == circuitKey) c.color = '#ef5350'})
                break;
            default: 
                element.circuits.map(c => { if(c.key == circuitKey) c.color = '#66BB6A'})
                break;
        }
    })
}

const setHighestAlarm = (circuitAlarms, thingCircuitsEnabled) => {
    let highestAlarm = 0;
    const alarms = [];
    //por cada circuito insertamos su alerta y que circuito es para poder armar el label
    thingCircuitsEnabled.forEach( circuitKey => {
        alarms.push({
            alarm: NUMBER_ALARM[circuitAlarms[circuitKey]],
            circuit: CIRCUIT_TEXT_KEY[circuitKey]
        })
    })
    //Este doble recorrido de alarms es posible porque es conocido que el máximo de elementos del arreglo será 6
    alarms.forEach((alarm) => {
        if (alarm.alarm > highestAlarm) {
            highestAlarm = alarm.alarm;
        }
    })
    let circuits = "";
    alarms.forEach(alarm => {
        if(alarm.alarm == highestAlarm) circuits += i18n.t("circuits."+alarm.circuit);
    })
    return {
        type: NUMBER_ALARM_TO_KEY[highestAlarm],
        circuits
    };
}

const getCircuitsMaxPressures = (pressures, keys, type) => {
    const result = {}
    keys.forEach(key => {
        //La presión de ajuste a utilizar será según el tipo de presión: alta, baja o base
        const circuitPressures = pressures[key]
        const pressure = circuitPressures.high ? parseFloat(circuitPressures.high) : circuitPressures.base ? parseFloat(circuitPressures.base) : 0;
        //La presión máxima se calcula de la presión de ajuste + 25% de dicha presión
        result[key] =  pressure + pressure*0.25
    })
    return result;
}

const compareBestWorst = (a, b, bestFirst) => {
    const aColor = getTimestampFormatted(a.trackTimestamp)?.timestampColor;
    const bColor = getTimestampFormatted(b.trackTimestamp)?.timestampColor;
    const aColorValue = aColor === TIMESTAMP_COLOR.GREEN ? 3 : aColor === TIMESTAMP_COLOR.YELLOW ? 2 : 1;
    const bColorValue = bColor === TIMESTAMP_COLOR.GREEN ? 3 : bColor === TIMESTAMP_COLOR.YELLOW ? 2 : 1;
    if (aColorValue < bColorValue) return 1;
    if (aColorValue > bColorValue) return -1;
    if(!bestFirst) {
        [a,b] = [b,a];
    }
    const a1 = Object.values(a.caliper.states).filter(item => item === 'ALERT_1').length
    const a2 = Object.values(a.caliper.states).filter(item => item === 'ALERT_2').length
    const a3 = Object.values(a.caliper.states).filter(item => item === 'ALERT_3').length
    const b1 = Object.values(b.caliper.states).filter(item => item === 'ALERT_1').length
    const b2 = Object.values(b.caliper.states).filter(item => item === 'ALERT_2').length
    const b3 = Object.values(b.caliper.states).filter(item => item === 'ALERT_3').length
    if (a3 < b3) return -1;
    if (a3 > b3) return 1;
    if (a2 < b2) return -1;
    if (a2 > b2) return 1;
    if (a1 < b1) return -1;
    if (a1 > b1) return 1;
    return 0;
}

export default {
    getAlertsData
}
