import http from "@/middleware";

export const alertsApi = {
    /**
     * Obtener lo datos para el reporte de alertas
     * @param {*} filters
     * @returns
     */
    async getAlertsData(filters) {
        return await http.post('/api/alert/report', filters)
    }
}

export default alertsApi
