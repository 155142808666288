export default {
    name: 'CircuitChartComponent',
    components: {},
    props: {
        id: {
            type: String,
            required: true
        },
        maxPressure: {
            type: Number,
            required: false,
        },
        currentPressure: {
            type: Number,
            required: false
        },
        inletPressure: {
            type: Number,
            required: false
        },
        isCalibrating: {
            type: Boolean,
            required: false
        },
        pressureUnit: {
            type: String,
            required: false,
            default: 'PSI'
        },
        circuitColor: {
            type: String,
            required: false
        },
        fixedPressure: {
            type: Object,
            required: false
        },
        pressureCaliper: {
            type: Object,
            required: false
        }
    },
    data: () => {
        return {
            currentPencentage: 70,
            pressureTooltip: 0,
        }
    },
    mounted() {
        // this.maxPressure += (this.maxPressure * 0, 1);
    },
    computed: {
        /**
         * Porcentaje de la línea que indica la presión de entrada
        */
        inletPressurePercentage() {
            let value = 70
            // obtencion de porcentaje con regla de 3
            if (this.maxPressure != null && this.pressureTooltip != null) {
                const pencentage = (this.pressureTooltip * 100);
                value = pencentage / this.maxPressure;
            }
            // metodo para obtener porcentaje anterior
            // if (this.maxPressure != null && this.inletPressure != null) {
            //     const pencentage = (this.inletPressure / this.maxPressure);
            //     value = pencentage * this.currentPencentage
            // }
            return value
        },
        /**
         * Porcentaje de la barra que indica la presión actual del circuito
        */
        currentPressurePercentage() {
            let value = 70
            // obtencion de porcentaje con regla de 3
            if (this.maxPressure != null && this.currentPressure != null) {
                const pencentage = (this.currentPressure * 100);
                value = pencentage / this.maxPressure;
            }
            // metodo para obtener porcentaje anterior
            // if (this.maxPressure != null && this.currentPressure != null) {
            //     const pencentage = (this.currentPressure / this.maxPressure);
            //     value = pencentage * this.currentPencentage
            // }
            return value
        },
        /**
         * Color de la barra de presión que indica si el cirucito se está calibrando o no
         */
        calibratingColor() {
            let color = 'rgba(120, 144, 156, 0.3)'
            if (this.isCalibrating != null && this.circuitColor) {
                color = this.circuitColor;
            }
            return color
        },
        /**
         * 
         */
        tooltipPressure() {
            if (this.fixedPressure?.type === 'B') {
                this.pressureTooltip = this.fixedPressure.base;
                return this.fixedPressure.base;
            }
            if (this.fixedPressure?.type === 'P' && this.pressureCaliper?.state2 === '00') {
                this.pressureTooltip = this.fixedPressure.low;
                return this.fixedPressure.low;
            }
            if (this.fixedPressure?.type === 'P' && this.pressureCaliper?.state2 === '01') {
                this.pressureTooltip = this.fixedPressure.high;
                return this.fixedPressure.high;
            }
            if (this.fixedPressure?.type === 'L-H' && this.pressureCaliper?.state2 === '00') {
                this.pressureTooltip = this.fixedPressure.low;
                return this.fixedPressure.low;
            }
            if (this.fixedPressure?.type === 'L-H' && this.pressureCaliper?.state2 === '01') {
                this.pressureTooltip = this.fixedPressure.high;
                return this.fixedPressure.high;
            }
            this.pressureTooltip = this.fixedPressure.base;
            return this.fixedPressure.base;
        }
    },
    methods: {
    }
}
