import { getGoogleMapsURL } from '@/tools/functions';
import { SPEEDOMETER_CHART_OPTIONS } from '@/constants/alert.constant';
import * as Highcharts from 'highcharts/highstock';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import CircuitChartComponent from './circuit-chart/CircuitChartComponent.vue'
import {unitParser} from '@colven/common-domain-lib/lib'
import store from "@/store/store";
import { ColvenTheme } from '@/constants/constants';

ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);

export default {
    name: 'AlertCardComponent',
    components: {
        CircuitChartComponent
    },
    props: {
        thingId: {
            type: String,
            required: true
        },
        thingName: {
            type: String,
            required: true
        },
        communicationDate: {
            type: String,
            required: false,
            default: () => null,
        },
        communicationDateColor: {
            type: String,
            required: false,
            default: 'error',
        },
        positionDate: {
            type: String,
            required: false,
            default: () => null,
        },
        positionDateColor: {
            type: String,
            required: false,
            default: 'error',
        },
        engineRunning: {
            type: Boolean,
            required: false,
            default: () => null,
        },
        pressureState: {
            type: [String, Function],
            required: false,
            default: () => 'B',
        },
        showState: {
            type: Boolean,
            required: true,
        },
        showPanelState: {
            type: Boolean,
            required: false
        },
        circuits: {
            type: Array,
            required: true,
        },
        georeference: {
            type: String,
            required: false,
            default: () => null,
        },
        lat: {
            type: Number,
            required: false,
            default: () => null,
        },
        lng: {
            type: Number,
            required: false,
            default: () => null,
        },
        speed: {
            type: Number,
            required: false,
            default: () => null,
        },
        alert: {
            type: String,
            required: false,
            default: () => null,
        },
        alertIcon: {
            type: String,
            required: false,
            default: 'fa-exclamation-circle',
        },
        alertColor: {
            type: String,
            required: false,
            default: 'success',
        },
        pressureUnit: {
            type: String,
            required: false,
            default: 'PSI',
        },
        speedUnit: {
            type: String,
            required: false,
            default: 'km/h',
        }
    },
    data: () => ({
        chartSpeed: null,
        requireIcon: false,
    }),
    mounted () {
        this.setSpeedometer();
    },
    computed: {
        /**
         * ícono de motor en marcha / apagado
         * @returns
         */
        engineRunningIcon () {
            return this.engineRunning === true ? 'mdi-engine' : 'mdi-engine-off' 
        },
        /**
         * ícono de estado de presión 
         * @returns
         */
        pressureStateIcon () {
            let icon = 'mdi-alpha-x-circle-outline';
            switch(this.pressureState) {
                case 'H':
                    icon = 'mdi-alpha-h-box';
                    this.requireIcon = false;
                    break;
                case 'L':
                    icon = 'mdi-alpha-l-box';
                    this.requireIcon = false;
                    break;
                case 'H/L':
                    icon = 'mdi-arrow-down-bold-box';
                    this.requireIcon = false;
                    break;
                case 'L/H':
                    icon = 'mdi-arrow-up-bold-box';
                    this.requireIcon = false;
                    break;
                case 'EM':
                    icon = 'truck-warning.svg';
                    this.requireIcon = true;
                    break;
                case 'EM/L':
                    icon = 'mdi-arrow-down-bold-box';
                    this.requireIcon = false;
                    break;
                case 'EM/H':
                    icon = 'mdi-arrow-up-bold-box';
                    this.requireIcon = false;
                    break;
                case 'E':
                    icon = 'truck-empty.svg';
                    this.requireIcon = true;
                    break;
                case 'E/L':
                    icon = 'mdi-arrow-down-bold-box';
                    this.requireIcon = false;
                    break;
                case 'E/H':
                    icon = 'mdi-arrow-up-bold-box';
                    this.requireIcon = false;
                    break;
                case 'SC':
                    icon = 'truck-semi.svg';
                    this.requireIcon = true;
                    break;
                case 'SC/L':
                    icon = 'mdi-arrow-down-bold-box';
                    this.requireIcon = false;
                    break;
                case 'SC/H':
                    icon = 'mdi-arrow-up-bold-box';
                    this.requireIcon = false;
                    break;
                case 'FC':
                    icon = 'truck-full.svg';
                    this.requireIcon = true;
                    break;
                case 'FC/L':
                    icon = 'mdi-arrow-down-bold-box';
                    this.requireIcon = false;
                    break;
                case 'FC/H':
                    icon = 'mdi-arrow-up-bold-box';
                    this.requireIcon = false;
                    break;
            }
            return icon;
        },
        /**
         * color de motor en marcha / apagado
         * @returns
         */
        engineRunningColor () {
            let color = 'gray'
            if (this.engineRunning != null) {
                color = this.engineRunning ? 'success' : ColvenTheme.light.turnedOff
            }
            return color
        },
        /**
         * tooltip del v-chip que indica si el motor está en marcha o detenido
         * @returns
         */
        engineRunningTooltip () {
            let tooltip = this.$t('alerts.engineRunningNoData')
            if (this.engineRunning != null) {
                tooltip = this.engineRunning ? this.$t('alerts.engineRunning') : this.$t('alerts.engineStopped')
            }
            return tooltip
        },
        pressureStateTooltip () {
            let tooltip = this.$t('alerts.pressureBase');
            switch(this.pressureState) {
                case 'H':
                    tooltip = this.$t('alerts.pressureStateHigh');
                    break;
                case 'L':
                    tooltip = this.$t('alerts.pressureStateLow');
                    break;
                case 'H/L':
                    tooltip = this.$t('alerts.pressureStateHighToLow');
                    break;
                case 'L/H':
                    tooltip = this.$t('alerts.pressureStateLowToHigh');
                    break;
                case 'EM':
                    tooltip = this.$t('alerts.pressureStateEmergency');
                    break;
                case 'EM/L':
                    tooltip = this.$t('alerts.pressureStateEmergencyToLow');
                    break;
                case 'EM/H':
                    tooltip = this.$t('alerts.pressureStateEmergencyToHigh');
                    break;
                case 'E':
                    tooltip = this.$t('alerts.pressureStateEmpty');
                    break;
                case 'E/L':
                    tooltip = this.$t('alerts.pressureStateEmptyToLow');
                    break;
                case 'E/H':
                    tooltip = this.$t('alerts.pressureStateEmptyToHigh');
                    break;
                case 'SC':
                    tooltip = this.$t('alerts.pressureStateSemi');
                    break;
                case 'SC/L':
                    tooltip = this.$t('alerts.pressureStateSemiToLow');
                    break;
                case 'SC/H':
                    tooltip = this.$t('alerts.pressureStateSemiToHigh');
                    break;
                case 'FC':
                    tooltip = this.$t('alerts.pressureStateFull');
                    break;
                case 'FC/L':
                    tooltip = this.$t('alerts.pressureStateFullToLow');
                    break;
                case 'FC/H':
                    tooltip = this.$t('alerts.pressureStateFullToHigh');
                    break;
            }
            return tooltip;
        },
        cardWidth () {
            let width = 500
            const circuitsCount = this.circuits.length
            if (circuitsCount > 3) {
                width = 500 + (circuitsCount - 3) * 166
            }
            return width;
        }
    },
    methods: {
        /**
         * Abre una pestaña con Google Maps apuntando a la posición del equipo
         */
        goToGoogleMaps () {
            const url = getGoogleMapsURL(this.lat, this.lng);
            window.open(url);
        },
        /**
         * crea el gráfico de velocidad
         */
        setSpeedometer () {
            this.chartSpeed = Highcharts.chart(`highchart-speedometer-${this.thingId}`,
                Highcharts.merge(SPEEDOMETER_CHART_OPTIONS, {
                    yAxis: {
                        min: 0,
                        max: Number(unitParser.parseSpeed(200, store.getters['user/getInternationalization'].unit)),
                        title: null,
                    },
                    credits: {
                        enabled: false
                    },
                    series: [
                        {
                            name: 'speed',
                            data: [this.speed],
                            dataLabels: {
                                format:
                                '<div style="text-align:center">' +
                                '<span style="font-size:16px">{y}</span><br/>' +
                                '<span style="font-size:10px;opacity:0.4">'+ 
                                this.speedUnit + 
                                '</span>' +
                                '</div>'
                            },
                            tooltip: {
                                valueSuffix: this.speedUnit
                            }
                        }
                    ]
                })
            )
        },
        /**
         * Obtener el nombre de un circuito a partir de su clave
         * @param {*} key
         * @returns
         */
        circuitName (key) {
            let name = this.$t(`alerts.circuit.${key}`)
            if (name === `alerts.circuit.${key}`) {
                name = key
            }
            return name
        }
    },
    watch: {
        speed: {
            handler: function (newVal) {
                if (this.chartSpeed) {
                    const point = this.chartSpeed.series[0].points[0];
                    point.update(newVal);
                }
            }
        }
    }
}